import "./App.scss";

function App() {
  return (
    <div className="p-10 md:p-20">
      <div className="mx-auto md:container">
        <h1 className="text-gray-400">
          <span className="text-white">Product Designer</span> focused on
          creating interactive user experiences. <br />
          <br />
          Making things @{" "}
          <a rel="noreferrer" target="_blank" href="https://motionstrand.com">
            Motionstrand
          </a>
          .
        </h1>
      </div>
    </div>
  );
}

export default App;
